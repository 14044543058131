@import 'utils/Functions';

$max-width: 1320px;
$accent-50: #f4ebff;
$accent-700: #6941c6;
$accent-900: #7f56d9;
$grey-25: #fcfcfd;
$grey-100: #f5f5f5;
$grey-200: #eaecf0;
$grey-300: #e6e6e6;
$grey-700: #344054;
$box-shadow: 0 1px 2px $grey-300;
$border-radius: 10px;

body {
  color: #101828;
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
}

.start-content {
  max-width: 720px;
  p {
    font-size: rem(17px);
    line-height: 180%;
  }
  h1,
  h2 {
    font-weight: 800;
  }
  h2 {
    margin: rem(40px) 0;
  }
}

.highlight {
  background: #f4ebff;
  color: #6941c6;
  margin-right: rem(2px);
  padding: 5px;
}

.ui-pagetitel {
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  margin-bottom: rem(40px);
}

.ui-sectiontitel {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  min-width: 100%;
}

// header
.ui-header {
  border-bottom: 1px solid $grey-200;
  margin-bottom: rem(10px);
  padding: rem(15px) rem(10px) rem(20px) rem(20px);
  .ui-brand {
    max-width: 70px;
  }
}

.ui-main {
  display: flex;
  height: 100%;
  min-height: 100vh;
}

.ui-component {
  background: white;
  background: $grey-25;
  flex-basis: fit-content;
  padding: rem(30px) rem(40px);
  @media (min-width: 768px) {
    margin-left: 280px;
    min-width: calc(100% - 360px);
  }
}

.ui-sidebar {
  background: white;
  border-right: 1px solid $grey-200;
  height: 100%;
  min-width: 270px;
  overflow-x: scroll;
  padding: 0 rem(5px);
  position: fixed;

  @media (max-width: 768px) {
    display: none;
  }

  .sidebar-navigation {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: rem(10px) rem(10px) rem(10px) rem(10px);
    .sidebar-subtitle {
      font-weight: 800;
      margin: rem(10px) 0;
      padding-left: rem(10px);
    }
    .ui-navigation-button {
      align-items: center;
      background: none;
      border: 0;
      border-radius: 6px;
      color: $grey-700;
      cursor: pointer;
      display: flex;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      margin: 0;
      padding: 0;
      padding: 8px 20px;
      text-align: left;
      width: 100%;

      &.active {
        background: $accent-50;
        color: $accent-700;
      }
    }
  }
  .sidebar-actions {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: rem(20px) rem(10px) rem(20px) rem(10px);
    width: calc(100% - 30px);
    @media (min-height: 870px) {
      bottom: 0;
      position: absolute;
    }
  }
  .ui-action-button {
    align-items: center;
    background: $accent-50;
    border: 1px solid #dfdbdf;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: $accent-700;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    gap: 8px;
    justify-content: center;
    padding: 12px 20px;
    transition: all 0.2s ease-in-out;
    width: 100%;
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 13.9998L8 10.9998M11 13.9998C12.3968 13.4685 13.7369 12.7985 15 11.9998M11 13.9998V18.9998C11 18.9998 14.03 18.4498 15 16.9998C16.08 15.3798 15 11.9998 15 11.9998M8 10.9998C8.53214 9.61919 9.2022 8.29582 10 7.04976C11.1652 5.18675 12.7876 3.65281 14.713 2.59385C16.6384 1.53489 18.8027 0.986131 21 0.99976C21 3.71976 20.22 8.49976 15 11.9998M8 10.9998H3C3 10.9998 3.55 7.96976 5 6.99976C6.62 5.91976 10 6.99976 10 6.99976M3.5 15.4998C2 16.7598 1.5 20.4998 1.5 20.4998C1.5 20.4998 5.24 19.9998 6.5 18.4998C7.21 17.6598 7.2 16.3698 6.41 15.5898C6.02131 15.2188 5.50929 15.0044 4.97223 14.9878C4.43516 14.9712 3.91088 15.1535 3.5 15.4998Z' stroke='%236941c6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 14px;
      content: '';
      display: block;
      height: 14px;
      width: 14px;
    }
    &:hover {
      background: #f5e8ff;
    }
  }
}

.ui-elements {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  .ui-colors-element {
    background: #fff;
    border: 1px solid $grey-200;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    .ui-colors-indicator {
      border-radius: 80px;
      height: 50px;
      margin: 15px 0 15px 15px;
      width: 50px;
    }
    .ui-form {
      padding: 0 15px 15px 15px;
    }
  }
}
.ui-colors {
}

.ui-form-element {
  display: flex;
  flex-direction: column;
}
.ui-input {
  align-items: center;
  align-self: stretch;
  background: #ffffff;
  border: 1px solid $grey-300;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  font-size: 13.3px;
  gap: 8px;
  height: 16px;
  padding: 10px 14px;
}

.ui-select-wrapper {
  background: #ffffff;
  border: 1px solid $grey-300;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  font-size: 13.3px;
  margin-top: rem(5px);
  min-width: 200px;
  position: relative;
  .ui-select-items:not(.ui-select-items--active) {
    display: none;
  }

  .ui-select-trigger {
    align-items: center;
    display: flex;
    display: flex;
    font-size: 14px;
    gap: 10px;
    padding: 10px 14px;
  }
  .ui-select-items--active {
    background: #ffffff;
    border: 1px solid $grey-300;
    border-radius: 8px;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    font-size: 13.3px;
    left: 0;
    max-height: 200px;
    min-width: 278px;
    overflow-x: scroll;
    padding: rem(8px) rem(0px);
    position: absolute;
    top: 45px;
    z-index: 3;
  }

  .ui-select-indicator {
    display: none;
    &.ui-select-indicator--color {
      border-radius: 20px;
      display: block;
      height: 20px;
      width: 20px;
    }
    .ui-select-title {
      display: flex;
      flex: 1;
    }
    &[style='background-color: transparent;'],
    &[style='background-color: var(--transparent);'],
    &[style='background-color: white;'],
    &[style='background-color: var(--white);'] {
      border: 1px solid $grey-300;
      height: 18px;
      width: 18px;
    }
  }
  .ui-select-item {
    align-items: center;
    display: flex;
    font-size: 14px;
    gap: 10px;
    padding: 6px 13px;
  }
  @media (min-width: 768px) {
    margin-top: 0;
    min-width: 280px;
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23344054' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 12px;
    bottom: 0;
    content: '';
    display: block;
    height: 12px;
    position: absolute;
    right: 15px;
    top: 17px;
    width: 12px;
  }
  .ui-select {
    align-items: center;
    align-self: stretch;
    appearance: none;
    -webkit-appearance: none;
    background: #ffffff;
    border: 1px solid $grey-300;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
    display: flex;
    font-size: 13.3px;
    gap: 8px;
    height: 38px;
    padding: 10px 14px;
    width: 100%;
  }
}

.ui-label {
  color: $grey-700;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: rem(4px);
}

.ui-button {
  align-items: center;
  background: #ffffff;
  border: 1px solid $grey-300;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  display: flex;
  font-size: rem(14px);
  gap: 8px;
  justify-content: center;
  padding: 9.5px 20px;
}

.ui-slider-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 280px;
  position: relative;

  .ui-slider {
    -webkit-appearance: none;
    appearance: none;
    background: $grey-300;
    border-radius: 3px;
    height: 7px;
    min-width: 120px;
    outline: none;
    -webkit-transition: 0.2s;
    width: 100%;
    @media (min-width: 768px) {
      min-width: 180px;
    }

    &::-moz-range-thumb {
      background: #fff;
      border: 1px solid $accent-900;
      border-radius: 20px;
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }
  .ui-slider-value {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    font-size: 14px;
    margin-left: 20px;
    padding: 8px 7px;
    text-align: center;
    width: 60px;
  }
}

.ui-component-settings {
  margin-top: rem(50px);
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }
  .ui-component-settings-header {
    align-items: center;
    display: flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: rem(20px);
    min-width: 100%;
  }
  .ui-component-settings-item {
    align-items: center;
    border-bottom: 1px solid $grey-200;
    display: flex;
    flex: 1;
    margin-bottom: rem(15px);
    padding-bottom: rem(20px);
    padding-left: 20px;
    padding-top: 5px;
    .ui-label {
      margin-bottom: 0;
      width: 100px;
      @media (min-width: 768px) {
        width: 200px;
      }
    }
    .ui-input {
      min-width: 250px;
    }
    &:nth-child(-n - 2) {
      border-bottom: none;
    }
  }
  .ui-component-settings-item:only-child,
  .ui-component-settings-item:nth-child(2):nth-last-child(1),
  .ui-component-settings-item:nth-child(1):nth-last-child(2),
  .ui-component-settings-item:nth-child(3):nth-last-child(1),
  .ui-component-settings-item:nth-child(4):nth-last-child(1),
  .ui-component-settings-item:nth-child(3):nth-last-child(2),
  .ui-component-settings-item:nth-child(5):nth-last-child(1),
  .ui-component-settings-item:nth-child(6):nth-last-child(1),
  .ui-component-settings-item:nth-child(5):nth-last-child(2),
  .ui-component-settings-item:nth-child(7):nth-last-child(2),
  .ui-component-settings-item:nth-child(8):nth-last-child(1) {
    border-bottom: none;
  }
}
.ui-component-settings-actions {
  display: flex;
  margin-bottom: 20px;
  max-width: calc(100vw - 80px);
  @media (min-width: 768px) {
    position: absolute;
    right: 40px;
  }
  .ui-button {
    cursor: pointer;
    &.active {
      background: $accent-50;
      color: $accent-700;
      font-weight: 600;
    }
    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-right: 0;
      border-top-right-radius: 0;
    }
  }
  &.ui-component-preview-small {
    & + .ui-component-view {
      .ui-component-preview {
        margin: 0 auto;
        max-width: 468px;
      }
    }
  }
  &.ui-component-preview-medium {
    & + .ui-component-view {
      .ui-component-preview {
        margin: 0 auto;
        max-width: 768px;
      }
    }
  }
  &.ui-component-preview-large {
    & + .ui-component-view {
      .ui-component-preview {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }
}
.ui-component-group {
  display: flex;
  margin-bottom: rem(10px);
  max-width: calc(100vw - 80px);
  .ui-button {
    cursor: pointer;
    &.active {
      background: $accent-50;
      color: $accent-700;
      font-weight: 600;
    }
    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-right: 0;
      border-top-right-radius: 0;
    }
  }
  &.ui-component-preview-small {
    & + .ui-component-view {
      .ui-component-preview {
        margin: 0 auto;
        max-width: 468px;
      }
    }
  }
  &.ui-component-preview-medium {
    & + .ui-component-view {
      .ui-component-preview {
        margin: 0 auto;
        max-width: 768px;
      }
    }
  }
  &.ui-component-preview-large {
    & + .ui-component-view {
      .ui-component-preview {
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }
}
.ui-component-preview {
  background: #fff;
  border: 1px solid $grey-200;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  max-width: 100%;
  padding: rem(30px);
  transition: all 0.2s ease-in-out;
  iframe {
    border: 0;
    height: 100%;
    width: 100%;
  }
}

.ui-component-wrap {
  background: #fff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-top: rem(40px);
  max-width: 100%;
  padding: 1.875rem;
  transition: all 0.2s ease-in-out;
}

.ui-component-markup {
  background: #1c1c1c;
  border-radius: 8px;
  font-size: rem(17px);
  font-size: 15px;
  line-height: 23px;
  margin-left: auto;
  margin-right: auto;
  margin-top: rem(40px);
  padding: 6px 20px;
  pre {
    background: #1c1c1c !important;
  }
}
